<template>
  <header-t></header-t>
  <div class=" nPage taskShowPage">
    <div class=" BcBl1">
      <div class="nW breadcrumb">
        <!-- <el-page-header content="任务详情" @back="goBack" /> -->
        <el-breadcrumb separator-icon="Files" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }"
            ><svg-icon icon="user"
          /></el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">任务大厅</el-breadcrumb-item>
          <!-- <el-breadcrumb-item><a href="/">任务详情</a></el-breadcrumb-item> -->
          <el-breadcrumb-item>任务详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="nW indexTaskHall Mt24">
      <div class="taskTtiel">
        {{ taskObj.taskName }}
      </div>
      <div class="taskShowBox">
        <div class="taskTesBox">
          <div class="tBox">
            <div class="Ptit"><strong>任务内容</strong></div>
            <div class="Ptes" v-html="taskObj.taskContent"></div>
          </div>
          <div class="tBox">
            <div class="Ptit"><strong>验收标准</strong></div>
            <div class="Ptes" v-html="taskObj.acceptanceCriteria"></div>
          </div>

          <div class="tBox" v-if="hbrwTask.length > 0">
            <div class="Ptit"><strong>后补任务</strong></div>
            <div class="Ptes" v-for="(ihm, ihx) in hbrwTask" :key="ihx">
              <div>
                {{ ihm.taskContent }}
              </div>
              <div class="FsH6">
                <span>
                  任务积分： <strong class="FsHs">{{ ihm.tsIntegral }}</strong>
                </span>
                <span class="disInB Ml36">
                  任务时间：{{ ihm.startDatetime }} -
                  {{ ihm.endDatetime }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="descriptions">
          <el-descriptions column="1">
            <el-descriptions-item label="所属分类">
              <span class="FsB1">{{ taskObj.fieldLabel }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="时间要求">
              <span class="FsB1">{{ taskObj.hours }}</span> 工作小时
            </el-descriptions-item>
            <el-descriptions-item label="计划时间">
              <span class="FsB1">{{ taskObj.planStartDate }}</span> 至
              <span class="FsB1">{{ taskObj.planEndDate }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="任务预算">
              <strong class="FsHs F14">{{ taskObj.integral }} 积分</strong>
            </el-descriptions-item>
            <el-descriptions-item label="雇佣人数">
              <span class="FsB1">{{ taskObj.planPeopleNumber }} 人</span>
            </el-descriptions-item>
            <el-descriptions-item label="承接人员类型">
              <span class="FsB1">{{
                taskObj.peopleType === 1
                  ? '不限'
                  : taskObj.peopleType === 2
                  ? '企业'
                  : '个人'
              }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="承接保证金">
              <span class="FsB1">{{ taskObj.margin }} 积分</span>
            </el-descriptions-item>
            <el-descriptions-item label="发布用户/企业">
              <span class="FsB1">{{ taskObj.name }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="发布时间">
              <span class="FsB1">{{ taskObj.insertTime }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div
        class="cyBtnsDiv"
        v-if="taskObj.state === 2 || taskObj.repair !== null"
      >
        <div v-if="tjState === false">
          <span class="cyBtn" @click="canyuqian">立即参与并冻结积分</span>
        </div>
        <div v-else class="bmcg">
          <svg-icon icon="access" />
          您已报名！请耐心等待发布人确认
        </div>
      </div>
      <div class="ybmDiv">
        <div class="Ptit">
          <strong class="tit">报名用户</strong>
          <span
            class="rset"
            v-if="
              (addUs && taskObj.state === 2) ||
                (addUs && taskObj.state === 3 && taskObj.repair !== null)
            "
          >
            <el-button
              type="primary"
              size="small"
              @click="dialoginVisible = true"
              >雇主确认</el-button
            >
          </span>
        </div>
        <div v-if="!dialoginVisible">
          <ul class="bmUserDiv" v-if="bmList.length > 0">
            <li v-for="(icm, icx) in bmList" :key="icx">
              <em class="photo"><img :src="icm.photo"/></em>
              <span class="name">{{ icm.name }}</span>
              <span class="zhongbiao">{{
                icm.winning === 0
                  ? '未选择'
                  : icm.winning === 1
                  ? '中标'
                  : '未选中'
              }}</span>
              <span class="evaluation">
                <!-- 评分： -->
                <i
                  v-for="iv in icm.evaluation"
                  :key="'s' + iv"
                  class="el-icon-star-on"
                  style="color:#d60"
                ></i>
              </span>
              <span class="time">{{ icm.changeTime }}</span>
            </li>
          </ul>
          <div v-else>
            <div class="TxC P16">暂无人员报名</div>
          </div>
        </div>
        <div v-else>
          <el-alert
            :title="
              '请跟据任务分工，合理分配 ' +
                kfpjifen +
                ' 积分，分配积分的和必须等于' +
                kfpjifen +
                '积分，否则不能提交。'
            "
            type="warning"
            effect="dark"
          />
          <template v-if="bmHB === false">
            <!-- 初次选择 -->
            <ul class="bmUserDiv">
              <el-checkbox-group v-model="baomingArr">
                <li v-for="(icm, icx) in bmList" :key="icx" class="nhh">
                  <span class="usname">
                    <el-checkbox :label="icm.usId" name="type">
                      {{ icm.name }}
                    </el-checkbox>
                  </span>

                  <span class="inputJifen">
                    <el-input v-model="icm.jifen" placeholder="积分">
                      <template #prepend>得积分</template>
                    </el-input>
                  </span>
                  <span class="inputTex">
                    <el-input
                      v-model="icm.taskContent"
                      placeholder="分配工作内容"
                    />
                  </span>
                  <p style="color:#d00;">{{ icm }}</p>
                </li>
              </el-checkbox-group>
            </ul>
            <div>
              <el-button type="primary" @click="querenFun" size="small">
                提交选定人员
              </el-button>
            </div>
          </template>
          <template v-else>
            <!-- 后补任务 -->

            <div v-for="(item, imx) in hbrwTask" :key="imx" class="hbTaskXz">
              <div class="fxBox">
                <div class="fx1">
                  选择人员1：
                  <el-select
                    size="small"
                    v-model="item.performUserID"
                    class="m-2"
                    placeholder="选择人员"
                  >
                    <el-option
                      v-for="ikl in bmList"
                      :key="ikl.ID"
                      :label="ikl.name"
                      :value="ikl.usId"
                    />
                  </el-select>
                </div>
                <div class="fx1">
                  得
                  <strong class="FsHs">{{ item.tsIntegral }}</strong> 积分
                </div>
              </div>
              <div class="Ptes">
                {{ item.taskContent }}
              </div>
            </div>
            <div style="margin:24px 0;">
              <el-button type="primary" @click="querenFun2" size="small">
                提交选定人员
              </el-button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <footer-s></footer-s>
</template>
<script setup>
import { ref } from 'vue'
import { sign, getItem, setItem, toUsId } from '@/utils/storage'
import HeaderT from '@/components/Public/Header'
import FooterS from '@/components/Public/Footer'
import { ElMessage, ElMessageBox } from 'element-plus'
import eventbus from '@/utils/eventBus'
import {
  getTaskShow,
  getTaskRegistration,
  getTaskRegistrationList,
  getTaskRegistrationList2,
  getRepairSubTasks,
  postRegistrationQueren,
  getSubTasksEdit
} from '@/api/task'
import { useRouter } from 'vue-router'
const router = useRouter()
const taskId = router.currentRoute.value.query.d
if (taskId === undefined) {
  console.log('参数ID不存在=', taskId)
}
const kfpjifen = ref(0)
const taskObj = ref({})
const tjState = ref(false)
const addUs = ref(false)
const dialoginVisible = ref(false)
const baomingArr = ref([])
const usinfo = getItem('userinfo')
const myId = toUsId(getItem('token') === null ? '' : getItem('token'))
console.log('usinfo000-', usinfo)

// 详情
const getData = async () => {
  taskObj.value = await getTaskShow(sign({ id: taskId }))
  if (usinfo !== null) {
    if (taskObj.value.addUserID === parseInt(myId)) {
      addUs.value = true
    }
  }
  kfpjifen.value = taskObj.value.integral

  if (taskObj.value.repair === null) {
    bmHB.value = false
  } else {
    bmHB.value = true
    houbuTask()
  }

  baomingList() // 报名列表
}
getData()

const canyuqian = () => {
  console.log(taskObj.value.peopleType)
  console.log('usinfo==', usinfo)
  // if (
  //   taskObj.value.peopleType === 3 &&
  //   (usinfo.value.comSH === 0 || usinfo.value.comSH === 2)
  // ) {
  //   console.log('可以报名', usinfo.value.comSH)
  // }
  //
  if (getItem('token') === null) {
    eventbus.emit('loginWindow', 'login') // 打开登录窗口
    return false
  } else if (
    taskObj.value.peopleType === 2 &&
    (usinfo.comSH === null || usinfo.comSH === 1 || usinfo.comSH === 3)
  ) {
    // 限制要求企业
    if (usinfo.comSH === null) {
      ElMessage.error('您不是企业用户,无法参与')
    } else if (usinfo.comSH === 1) {
      ElMessage.error('请联系企业管理员对您审核')
    } else if (usinfo.comSH === 3) {
      ElMessage.error('企业管理员拒绝您的申请')
    } else {
    }
    console.log('限制要求企业', usinfo.comSH)
  } else if (parseInt(toUsId(getItem('token'))) === taskObj.value.addUserID) {
    ElMessage.error('您不能报名自己的任务')
    return false
  } else if (usinfo.integral < parseInt(taskObj.value.margin)) {
    ElMessageBox.alert('您的积分不足，请先充值积分！', '积分不足', {
      confirmButtonText: '确定',
      callback: action => {
        router.push('/usindex')
        return false
      }
    })
  } else {
    ElMessageBox.confirm(
      '报名将冻结' + taskObj.value.margin + '积分，若积分不够，请先充值积分！',
      '确定要报名么',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
    )
      .then(() => {
        console.log('确定报名')
        canyu()
      })
      .catch(() => {
        console.log('取消报名')
      })
  }
}
const canyu = async () => {
  if (usinfo.integral < taskObj.value.margin) {
    ElMessage.error('您的积分不足，请先充值积分')
    goPage('/usindex')
    // router.push('/usindex')
  } else {
    var bmb = await getTaskRegistration(
      sign({ taskid: taskId, bzj: taskObj.value.margin })
    )
    var usi = getItem('userinfo')
    usi.integral = bmb
    setItem('userinfo', usi)
    tjState.value = true
    baomingList()
  }
}
// 后补任务
const hbrwTask = ref([])
const houbuTask = async () => {
  var hbrw = await getRepairSubTasks(sign({ taskid: taskId }))
  var hbjf = 0
  console.log('后补任务-返回', hbrw)
  hbrw.forEach(isv => {
    hbjf += isv.tsIntegral
    isv.performUserID = ''
  })
  kfpjifen.value = hbjf
  hbrwTask.value = hbrw
  console.log('后补任务==', hbrw)
}

// 报名列表
const bmList = ref([])
const bmHB = ref(false) // 撤销后的后补列表
const baomingList = async () => {
  var bmdata = null
  if (bmHB.value === false) {
    bmdata = await getTaskRegistrationList(sign({ taskid: taskId }))
  } else {
    // 后补人员
    bmdata = await getTaskRegistrationList2(sign({ taskid: taskId }))
  }
  if (bmdata.length > 0) {
    bmdata.forEach(itm => {
      itm.taskContent = '' // 子任务内容
      itm.jifen = 0
      if (usinfo !== null) {
        if (itm.usId === parseInt(myId)) tjState.value = true
      }
      itm.photo = window.g.baseURL + itm.photo
    })
  }
  bmList.value = bmdata
}

// 后补任务 - 雇主确认
const querenFun2 = async () => {
  // 后补任务
  var ss = false
  var ihbID = ''
  var ihbUS = ''
  var xus = []

  hbrwTask.value.forEach((itr, itx) => {
    if (itr.performUserID === '') {
      ss = true
    } else {
      if (itx > 0) {
        ihbID += '|'
        ihbUS += '|'
      }
      ihbID += itr.tsid
      ihbUS += itr.performUserID
      xus.push(itr.performUserID)
    }
  })
  if (ss) {
    ElMessage.error('请选择人员')
    return false
  }
  // 需要退还保证金的用户
  var iz = false
  var th = [] // 退还的用户ID

  bmList.value.forEach(isv => {
    iz = false
    xus.forEach(xx => {
      if (xx === isv.usId) {
        iz = true
      }
    })
    if (iz === false) {
      th.push(isv.usId)
    }
  })
  var parmas11 = {
    ihbID: ihbID,
    tg: 'ok',
    taskID: taskId,
    ihbUS: ihbUS, // 后补人员ID
    thbzj: th.join('|') // 退还保证金
  }
  console.log(parmas11)

  await getSubTasksEdit(sign(parmas11))
  dialoginVisible.value = false
  getData()
}

// 雇主确认
const querenFun = async () => {
  // 未被选中的人
  var wxz = ''
  var zjf = 0

  zjf = parseInt(taskObj.value.integral) // 预算

  var fjf = 0
  if (baomingArr.value.length === 0) {
    ElMessage.error('请选择执行人')
    return false
  }
  // 执行人数 >  报名人数
  if (bmList.value.length > baomingArr.value.length) {
    var nrwk = 0
    var s = 0
    bmList.value.forEach(gg => {
      s = 0
      baomingArr.value.forEach(hh => {
        if (gg.usId === hh) {
          s = 1
          fjf += parseInt(gg.jifen)

          if (gg.jifen === 0) {
            nrwk = 1
          }
          if (gg.taskContent === '') {
            nrwk = 2
          }
        }
      })
      if (s === 0) {
        if (wxz !== '') {
          wxz += '+'
        }
        wxz += gg.usId
      }
    })
    if (nrwk === 1) {
      ElMessage.error('任务积分不能为0')
      return ''
    }
    if (nrwk === 2) {
      ElMessage.error('任务内容不能为空')
      return ''
    }
    console.log('--积分--', fjf, zjf)
  } else if (bmList.value.length === baomingArr.value.length) {
    var btg = false
    var btgTx = ''
    bmList.value.forEach(rx => {
      if (rx.jifen === 0) {
        btg = true
        btgTx = '积分不能为0'
      } else {
        fjf += parseInt(rx.jifen)
      }
      if (rx.taskContent === '') {
        btg = true
        btgTx = '工作内容不能为空'
      }
    })
    if (btg) {
      ElMessage.error(btgTx)
      return ''
    }
  }
  if (baomingArr.value.length !== taskObj.value.planPeopleNumber) {
    // 选中的乙方人数 需等于 设定人数
    ElMessage.error('雇用人数与设定人数不符，请按任务修改选定人数')
    return ''
  }

  if (fjf !== zjf) {
    if (fjf < zjf) {
      ElMessage.error('任务积分分配有误，请核实是否完成')
    } else {
      ElMessage.error('分配的积分已超过' + zjf + '分，请修正')
    }
    return ''
  }
  if (wxz === '') wxz = '0'

  var qrnr = ''
  var fjifen = ''
  baomingArr.value.forEach((ia, aa) => {
    bmList.value.forEach(ib => {
      if (ib.usId === ia) {
        if (aa > 0) {
          qrnr += '|'
          fjifen += '|'
        }
        qrnr += ia + '+/+' + ib.taskContent + ''
        fjifen += ib.jifen + ''
      }
    })
  })

  var parmas = {
    createUserID: myId,
    taskID: taskId,
    ztask: qrnr,
    jifen: fjifen,
    ywxz: wxz,
    thjf: taskObj.value.margin
  }
  var bmdata = await postRegistrationQueren(sign(parmas))
  dialoginVisible.value = false

  getData()
  console.log('返回-', bmdata)
}
const goPage = v => {
  router.push(v)
}
</script>
<style scoped>
.hbTaskXz {
  padding: 20px 2px;
  border-bottom: solid 1px #ddd;
}
.bmUserDiv li {
  display: flex;
  margin: 12px;
  line-height: 32px;
}
.bmUserDiv li em.photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}
.bmUserDiv li span {
  display: block;
  flex: 1;
}
.bmUserDiv li span.name {
  color: #08f;
}
.bmUserDiv li span.time {
  font-size: 14px;
  color: #888;
}
.bmUserDiv li span.usname {
  width: 280px;
  flex: none;
}
.bmUserDiv li span.inputTex {
  flex: 1;
}
.bmUserDiv li .evaluation i {
  font-size: 30px;
  line-height: 24px;
}
.bmUserDiv li.nhh {
  border-bottom: dotted 1px #ddd;
}
.bmUserDiv li.nhh .usname {
  width: 160px;
  display: inline-block;
}
.bmUserDiv li.nhh .inputJifen {
  flex: initial;
  width: 160px !important;
  display: inline-block;
}
.bmUserDiv li.nhh .inputJifen > .el-input > .el-input-group__prepend {
  padding: 0px 12px !important;
}

.bmUserDiv li.nhh .inputTex {
  flex: 1;
  margin-left: 16px;
}
.cyBtnsDiv {
  padding: 14px;
  text-align: center;
  margin-right: 4vw;
}
.cyBtnsDiv .cyBtn {
  padding: 14px 40px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  background: rgb(4, 103, 122);
}
.cyBtnsDiv .cyBtn:hover {
  background-color: rgb(36, 90, 184);
}
.ybmDiv {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: solid 1px #ddd;
}
.bmcg {
  padding: 2rem;
  margin-top: 2rem;
  font-size: 1.4rem;
}
</style>
